import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'
import account from './account-management/moduleAccountManagement'
import products from './product-management/moduleProductManagement'
import alerts from './alert-management/moduleAlertManagement'
import address from './address-management/moduleAddressManagement'
import orders from './order-management/moduleOrderManagement'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    account,
    products,
    alerts,
    address,
    orders,
  },
  strict: process.env.DEV,
})
