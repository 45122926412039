import { orderService } from '@/_services/order.service'

export default {
  index({ commit }, { currentPage = 1 }) {
    return orderService.index(currentPage).then(
      response => {
        commit('setOrders', response.data.orders)
        return Promise.resolve(response.data)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  show({ commit }, { orderId }) {
    return orderService.show(orderId).then(
      response => {
        commit('setOrder', response.data.orders)
        return Promise.resolve(response.data)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  createInvoice({ commit }, {
    orderId,
    rfc,
    cfdiUse,
    regimenFiscal,
    razonSocial,
    domicilioFiscal,
  }) {
    console.log(cfdiUse)
    return orderService.createInvoice(orderId, rfc, cfdiUse, regimenFiscal, razonSocial, domicilioFiscal).then(
      response => {
        commit('setInvoice', response.invoice)
        return Promise.resolve(response)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  downloadInvoice(_, { orderId }) {
    return orderService.downloadInvoice(orderId)
  },
  downloadInvoicePdf(_, { orderId }) {
    return orderService.downloadInvoicePdf(orderId)
  },
}
