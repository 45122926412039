import Vue from 'vue'
import { API_URL } from '@/config/app'

// axios
import axios from 'axios'

axios.defaults.withCredentials = true

const axiosIns = axios.create({
  // You can add your headers here
  // ================================
  baseURL: API_URL,
  crossDomain: true,
  // timeout: 1000,
  // headers: {'X-Custom-Header': 'foobar'}
})

Vue.prototype.$http = axiosIns

export default axiosIns
