import { productService } from '@/_services/product.service'

export default {
  index({ commit }, {
    page,
    properties,
    search,
    priceRange,
    categorization,
    sortByPrice,
  }) {
    return productService.index(page, properties, search, priceRange, categorization, sortByPrice).then(
      products => {
        commit('setProducts', products)
        return Promise.resolve(products)
      },
      error => {
        console.log(error)
        return Promise.reject()
      },
    )
  },
  // eslint-disable-next-line no-unused-vars
  show({ commit }, { productSlug }) {
    console.log(productSlug)
    return productService.show(productSlug).then(
      product => Promise.resolve(product),
      error => {
        console.log(`error: ${error}`)
        return Promise.reject(error)
      },
    )
  },
  listCategories({ commit }) {
    return productService.listCategories().then(
      categories => {
        commit('setCategories', categories)
        commit('setBrands', categories)
        return Promise.resolve(categories)
      },
      error => {
        console.log(error)
        return Promise.reject()
      },
    )
  },
  addProductInCart({ commit }, { productSlug, variants }) {
    return productService.addProductInCart(productSlug, variants).then(
      products => {
        commit('setProductsInCart', products.data)
        /* dispatch('alerts/success', 'El producto se añadió al carrito correctamente', { root: true }) */
        return Promise.resolve(products)
      },
      error => {
        console.log(error)
        /* dispatch('alerts/error', 'Hubo un problema al añadir el producto al carrito', { root: true }) */
        return Promise.reject(error)
      },
    )
  },
  indexCart({ commit }) {
    return productService.indexCart().then(
      products => {
        commit('setProductsInCart', products.data ? products.data : {})
        return Promise.resolve(products)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  removeProductFromCart({ commit }, { item_id }) {
    return productService.removeProductFromCart(item_id).then(
      products => {
        commit('setProductsInCart', products.data)
        return Promise.resolve(products)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  updateProductFromCart({ commit }, { item_id, quantity }) {
    return productService.updateProductFromCart(item_id, quantity).then(
      products => {
        console.log(products)
        commit('setProductsInCart', products.data)
        return Promise.resolve(products)
      },
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
  buy(_, { params }) {
    console.log(params)
    return productService.buy(params).then(
      response => Promise.resolve(response),
      error => {
        console.log(error)
        return Promise.reject(error)
      },
    )
  },
}
