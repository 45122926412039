import axios from '@/libs/axios'
import { userService } from '@/_services/user.service'

function handleResponse(response) {
  console.log(response)
  if (response.status === 401) {
    userService.logout()
    // eslint-disable-next-line prefer-promise-reject-errors
    return Promise.reject('Por motivos de seguridad se ha cerrado la sesión')
  }
  if (response.status === 422) {
    return Promise.reject(response)
  }
  return Promise.resolve(response)
}

function index(page, properties, search, priceRange, categorization, sortByPrice) {
  let parameters = ''
  let categories = ''
  let order = ''
  if (page) {
    parameters += `?page=${page}`
  }
  if (properties) {
    const keys = Object.keys(properties)
    keys.forEach(key => {
      parameters += `&${key}=${properties[key]}`
    })
  }
  if (search) {
    parameters += `&search=${search}`
  }
  if (priceRange) {
    parameters += `&price_range_initial=${priceRange[0]}`
    parameters += `&price_range_final=${priceRange[1]}`
  }
  if (categorization) {
    categories += `/${categorization}`
  }
  if (sortByPrice) {
    order += `&sort_by_price=${sortByPrice}`
  }

  return axios.get(`/products${categories}${parameters}${order}`)
    .then(
      response => handleResponse(response),
      error => {
        const { response } = error
        return handleResponse(response)
      },
    )
    .then(response => Promise.resolve(response.data))
    .catch(response => console.log(response))
}

function show(productSlug) {
  return axios.get(`/products/show/${productSlug}`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function listCategories() {
  return axios.get('/categories').then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).catch(error => handleResponse(error)).then(response => Promise.resolve(response.data))
}

function addProductInCart(product, variants) {
  return axios.post(`/cart/${product}`, variants).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function indexCart() {
  return axios.get('/cart').then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function removeProductFromCart(product) {
  return axios.delete(`/cart/${product}`).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function updateProductFromCart(product, quantity) {
  return axios.post(`/cart/${product}`, {
    qty: quantity,
    _method: 'patch',
  }).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

function buy(params) {
  return axios.post('/checkout/buying', params).then(
    response => handleResponse(response),
    error => {
      const { response } = error
      return handleResponse(response)
    },
  ).then(response => Promise.resolve(response.data))
}

// eslint-disable-next-line import/prefer-default-export
export const productService = {
  index,
  listCategories,
  show,
  addProductInCart,
  indexCart,
  removeProductFromCart,
  updateProductFromCart,
  buy,
}
