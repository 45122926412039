/* export const API_URL = 'http://app-demo83871.promostore.com.mx' */
/* export const API_URL = 'http://closed-e-commerce.test' */
export const API_URL = 'https://api.promostore.clarios.com'
/* export const API_URL = 'http://closed-ecommerce.test' */
/* export const API_URL = 'http://closed-e-commerce.hs' */

/* export const CONEKTA_API_KEY = "key_bHdk3rAUuqokthZ1gYmcSBQ" */
export const CONEKTA_API_KEY = 'key_F4ooyu5zyRdYMHjtMe2kTqA'

export const VARIANT_LABELS = {
  gender: 'Género',
  color: 'Color',
  size: 'Talla',
  brand: 'Marca',
}
